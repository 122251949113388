.auction-tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: var(--font-family-body);
  font-size: .75rem;
}

.auction-tag img {
  width: 24px;
  height: 24px;
  margin-right: .5rem;
}

.auction-tag.is-raffle {
  background-color: #e7def4;
  color: rgba(103, 51, 184, 1);
}

.auction-image {
  width: 100%;
}

.auction-title {
  font-size: 1.5rem;
  margin: .375rem 0 0;
}

.auction-by {
  font-size: 0.8rem;
  margin: 0 0 .375rem;
}

.auction-by a {
  color: var(--color-primary);
}

.auction-by a:hover {
  color: var(--color-primary-dark);
}

.auction-detail-container {
  margin: 1rem 0;
}

.auction-detail {
  padding: .25rem 0;
  font-size: .875rem;
  color: var(--color-natural-grey);
}

.auction-form {
  margin-bottom: .25rem;
}

.auction-form-button-container {
  margin-top: 1rem;
}

.auction-form-button-container .button {
  width: 100%;
}

.dark-mode .auction-detail {
  color: white;
}

@media all and (min-width: 900px) {
  .auction-form {
    display: flex;
  }

  .auction-form-input-container {
    flex: 1;
    margin-right: 1rem;
  }
  
  .auction-form-button-container {
    margin-top: 4px;
    flex: none
  }

  .auction-form-button-container .button {
    width: 240px;
  }
}
